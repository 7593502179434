<template>
  <div v-if="entriesChecked.length > 1 && (this.currentActivity.target_list === undefined && !reseted)" class="info-container ">
    <p class="text-reset">{{ $t("activities.multiple.edit.targets") }}</p>
    <RewriteButton :on-click="resetAllTargets"/>
  </div>

  <div v-else>
    <!-- Targets table -->
    <b-row class="m-0 mb-2 text-infos bg-white" v-for="target in targetsToPatch" v-bind:key="target.id" align-v="center" no-gutters>
      <b-col cols="10">
        <s v-if="!isRepeatedTarget(target)"> {{ target.designation }} </s>
        <span v-else> {{ target.designation }} </span>
      </b-col>
      <b-col cols="2">
        <b-button @click="onDelete(target.id)" class="button-icon button-transparent">
          <img :src="icon" class="mx-1" alt="icon delete action" />
        </b-button>
      </b-col>
    </b-row>
    <!-- Targets select list -->
    <Dropdown
      ref="dropdown"
      class="shape-custom-select"
      label="designation"
      :placeholder="$t('components.EditTargets.placeholder')"
      :options="targetsUpdated"
      @input="(selectedTarget) => addTargets(selectedTarget.id)"
      :clearSelection="true"
      :clearOnLoad="true"
      :clearOnSelect="true"
      :required="needTargets && mandatoryTarget && targetsToPatch.length === 0"
    />
  </div>
</template>

<script>
import delIcon from "@/assets/images/delete.svg";
import ActivityModule from "@/store/modules/activity";
import { mapGetters } from "vuex";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import LoadFormPartMixin from "@/mixins/LoadFormPartMixin";
import Dropdown from "@/components/activity/edit/Dropdown.vue";
import RewriteButton from "@/components/commons/RewriteButton.vue";

export default {
  name: "edit-targets-activity",
  mixins: [DetailUpdateMixin, LoadFormPartMixin],
  props: {
    useForm: {
      type: String,
      require: true,
    },
    inputFormPath: {
      type: String
    },
  },
  data() {
    return {
      targetSelected: "",
      icon: delIcon,
      targetsToPatch: [],
      targetsToPatchClone: [],
      targetsUpdated: [],
      selectedInputs: [],
      inputsStock: [],
      loadComponent: false,
      reseted: false
    };
  },
  components: {
    Dropdown,
    RewriteButton
  },
  mounted() {
    this.init();
  },
  beforeCreate() {
    const store = this.$store;
    if (store && store.state) {
      if (!store.state["activity"]) {
        store.registerModule("activity", ActivityModule);
      }
    }
  },
  methods: {
    /**
     * @description initialisation du tableau de cibles sélectionnées à partir de l'activité actuelle
     */
    init: async function () {
      this.loadComponent = false;
      if (Object.keys(this.currentEntry) == 0 && Object.keys(this.currentEditedEntry) == 0) {
        this.loadComponent = true;
        return;
      }
      if (["activity.edit", "tour.edit", "tour.duplicate"].includes(this.$route.name)) {
        if ((this.currentEditedEntry.targetsHydrated || this.currentEntry?.target_list)?.length != 0) {
          (this.currentEditedEntry.targetsHydrated || this.currentEntry?.target_list)?.map((target) => {
            this.targetsToPatch.push(target);
          });
        } else {
          this.targetsToPatch.length = 0;
        }
        this.getTargetsUpdated();
      }
      this.loadComponent = true;
    },
    /**
     * @description Ajoute une nouvelle cible au tableau des cibles de l'activité
     * @param {number} value l'id de la cible sélectionnée
     */
    addTargets(value) {
      this.targetSelected = value;
      if (this.targetSelected != "") {
        let id = this.targetSelected;
        for (let i = 0; i < this.inputForm.targetsFamilies.length; i++) {
          for (let j = 0; j < this.inputForm.targetsFamilies[i].targets.length; j++) {
            if (this.inputForm.targetsFamilies[i].targets[j].id === id) {
              let target = this.inputForm.targetsFamilies[i].targets[j];

              if (!this.checkIfTargetWasAdded(target.id)) {
                this.targetsToPatch.push(target);
              }
            }
          }
        }
      }
      this.targetSelected = "";
    },

    /**
     * @description Vérifie si une cible a déja été ajoutée au tableau des cibles de l'activité
     * @param {number} id l'id de la cible sélectionnée
     * @returns {boolean}
     *
     */
    checkIfTargetWasAdded(id) {
      let response = this.targetsToPatch.filter(function (elem) {
        if (elem.id == id) return elem;
      });

      if (response.length > 0) return true;
      return false;
    },

    /**
     * @description Supprime une cible du tableau des cibles de l'activité
     * @param {number} id l'id de la cible à supprimer
     */
    onDelete(id) {
      let idTarget = id;
      let val = this.targetsToPatch.filter(function (elem) {
        if (elem.id == idTarget) return elem;
      });
      const filter = this.targetsToPatch.indexOf(val[0]);
      if (filter > -1) this.targetsToPatch.splice(filter, 1);
    },

    /**
     * @description Verifie si une cible appartient déja au tableau des cibles possibles de l'activité
     * @returns {boolean}
     */
    isRepeatedTarget(target) {
      let repeatedTargets = 0;
      this.targetsUpdated.map((e) => {
        if (e.designation === target.designation) repeatedTargets++;
      });
      if (repeatedTargets === 0) return false;
      return true;
    },
    /**
     * Description: Confirme le chargement des données de ce composant.
     * @return {Boolean} Component loaded.
     */
    validateForm: function() {
      this.isValid = true;
      return this.loadComponent;
    },

    /**
     * @description retourne le tableau des cibles possibles en fonction du type des produits sélectionnés
     * @returns {array}
     */
    getTargetsUpdated() {
      if (this.inputForm.inputsStock != undefined) {
        this.selectedInputs = this.currentEditedEntry.inputs;
        this.inputsStock = [];
        if(this.selectedInputs)
        // on récupère les id des intrants en stock sélectionnés
          for (let i = 0; i < this.selectedInputs.length; i++) {
            this.inputsStock.push(this.selectedInputs[i].inputsStock);
          }
        this.targetsUpdated = [];
        let targetsFamiliesObject = {};
        for (let i = 0; i < this.inputsStock.length; i++) {
          let inputInForm = this.inputForm.inputsStock.find(e => e.id == this.inputsStock[i])
          if (inputInForm) {
            let productType = inputInForm.productType;
            // On récupère les familles de cibles applicables au type de produit associé à chaque intrant sélectionné
            this.inputForm.targetsFamilies.map((targetsFamily) => {
              if (targetsFamily.productTypes.includes(productType)) {
                if (!Object.keys(targetsFamiliesObject).includes(targetsFamily.id.toString())) {
                  targetsFamiliesObject[targetsFamily.id] = {
                    "head": {
                      id: targetsFamily.id + "group",
                      designation: targetsFamily.designation,
                      group: true
                    },
                    "targets": []
                  }
                }
                targetsFamily.targets.map((target) => {
                  if (inputInForm.targets?.includes(target.id)) {
                    // Retrait avant ajout de la cible pour forcer la mise à jour de l'attribut notAuthorized à false
                    targetsFamiliesObject[targetsFamily.id]["targets"] = targetsFamiliesObject[targetsFamily.id]["targets"].filter(
                      t => t.id != target.id
                    )
                    targetsFamiliesObject[targetsFamily.id]["targets"].push({
                      id: target.id,
                      designation: target.designation,
                      notAuthorized: false,
                    });
                  } else {
                    const existingTarget = targetsFamiliesObject[targetsFamily.id]["targets"].find(t => t.id == target.id)
                    if (!existingTarget) {
                      targetsFamiliesObject[targetsFamily.id]["targets"].push({
                        id: target.id,
                        designation: target.designation,
                        notAuthorized: true,
                      });
                    }
                  }
                });
              }

            });
          }
        }
        Object.keys(targetsFamiliesObject).forEach(targetFamilyId => {
          this.targetsUpdated.push(targetsFamiliesObject[targetFamilyId]["head"]);
          // On trie les cibles de façon à proposer celles autorisées en haut de liste
          targetsFamiliesObject[targetFamilyId]["targets"].sort((a, b) => {
            if (a.notAuthorized === b.notAuthorized) {
              return 0; // Les deux objets sont égaux en termes de tri
            }
            return a.notAuthorized ? 1 : -1; // Place ceux qui ont notAuthorized à false en premier
          });
          this.targetsUpdated.push(...targetsFamiliesObject[targetFamilyId]["targets"]);
        });
      }
      return this.targetsUpdated;
    },

    resetAllTargets(){
      this.reseted = !this.reseted
    },
  },
  computed: {
    ...mapGetters({
      currentActivity: "activity/currentEntry",
      activityTypes: "activityTypes",
      currentActivityType: "activityTypes",
      entriesChecked: "activity/entriesChecked"
    }),
    isOnActivity() {
      return this.$route.name.includes("activity");
    },
    inputForm() {
      return this.$store.getters[this.inputFormPath]
    },

    hideComponent() {
      return this.getTargetsUpdated.length == 0
    },

    /**
     * @description Vérifie si l'activité actuelle a au moins une cible applicable
     * @returns {boolean}
     */
    needTargets() {
    if(this.targetsUpdated.length != 0)
      return true;
    return false;
    },

    mandatoryTarget() {
      return this.inputForm.mandatoryTarget === true
    },
  },

  watch: {
    targetsToPatch: {
      deep: true,
      handler: function () {
        this.targetsToPatchClone = JSON.parse(JSON.stringify(this.targetsToPatch));
        this.targetsToPatchClone.length = 0;
        this.targetsToPatch.map((target) => {
          this.targetsToPatchClone.push(target.id);
        });
        this.$store.dispatch(this.storeEditAction, { targets: this.targetsToPatchClone });
      },
    },
    currentEditedEntry: {
      deep: true,
      handler: function () {
        this.selectedInputs = this.currentEditedEntry.inputs;
      },
    },
    selectedInputs: {
      handler: function () {
        this.getTargetsUpdated();
        this.targetsToPatch.map(target => {
          if(!this.isRepeatedTarget(target)) {
            if (!this.currentEntry || !this.currentEntry.target_list || (!this.currentEntry.target_list.includes(target) && !this.currentEntry.target_list.includes(target.id))){
              this.onDelete(target.id);
            }
          }
        });
      },
    },
    inputForm: {
      handler: function () {
        this.getTargetsUpdated();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: separate;
  border-spacing: 3px 3px;
  margin-bottom: 4px;
}

button {
  font-size: 0.8em;
  font-weight: bold;

  img {
    width: 1.5em;
  }
}

.text-reset {
    font-size: 14px;
    padding: 5px;
    color: #222e50;
    margin: 5px;
}

.info-container {
  display: flex;
  align-items: center; /* Centre verticalement les éléments */
  justify-content: space-between; /* Place le texte à gauche et le bouton à droite */
}

</style>
